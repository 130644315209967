import React, { useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import Layout from '../layout'
import { ThemeProvider } from '@material-ui/styles'
import { Box, Modal, CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { MarkdownHandler } from '@system'
import renderBloks from '@renderBloks'
import pgIcons from '@images/PG-to-PGBuild.svg'
import isInBrowser from '@helpers/is-in-browser'
import ACC_THEME from '@themes'
import Icon from '@system/icon'

const useStyles = makeStyles((theme) => ({
  modalBackdrop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalRoot: {
    outline: 0,
    width: '50%',
    backgroundColor: 'white',
    maxWidth: '660px',
    maxHeight: '85%',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  closeIconContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    color: ACC_THEME.palette.text.tertiary,
    margin: '10px 10px 0 0',
    width: '24px',
    cursor: 'pointer',
  },
  pgIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  planGridIcon: {
    margin: '1rem 2.5rem 0',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 1.5rem 0',
      width: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '60%',
    },
  },
  markdown: {
    margin: '0 3rem',
    '& h4': {
      fontSize: '2rem',
    },
    color: theme.palette.text.tertiary,
    [theme.breakpoints.down('sm')]: {
      margin: '0 2rem',
    },
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem',
    '& a': {
      whiteSpace: 'nowrap',
      margin: '0.5rem',
      textTransform: 'none',
    },
    [theme.breakpoints.down('md')]: {
      margin: '1rem 1.5rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  bottomLinkContainer: {
    backgroundColor: theme.palette.background.paper,
    borderBottomRightRadius: '15px',
    borderBottomLeftRadius: '15px',
    height: '4rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '2rem',
    '& a': {
      margin: '0 3rem',
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        margin: '0 2rem',
      },
    },
  },
}))

const FormModal = (props) => {
  const { markdown, CTAs, bottomLink, metaData } = props.blok
  const [open, setOpen] = useState(true)
  const classes = useStyles(props)

  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false)
  }

  useEffect(() => {
    if (isInBrowser) {
      const params = new URLSearchParams(window.location.search)
      // const isPlangridRedirect = params.get('pgr') === '1'
      setOpen(true)
    }
  }, [])

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={props.blok}>
          <Modal
            disableAutoFocus
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modalBackdrop}
          >
            <Box className={classes.modalRoot} disabled>
              <Box className={classes.closeIconContainer}>
                <Icon onClick={handleClose} styles={classes.closeIcon}>
                  CloseIcon
                </Icon>
              </Box>
              <Box className={classes.pgIconContainer}>
                <img
                  className={classes.planGridIcon}
                  alt="plangrid-transition-icons"
                  src={pgIcons}
                />
              </Box>
              <Box className={classes.markdown}>
                <MarkdownHandler>{markdown}</MarkdownHandler>
              </Box>
              <Box className={classes.ctaContainer}>{renderBloks(CTAs)}</Box>
              <Box className={classes.bottomLinkContainer}>
                {renderBloks(bottomLink, { onClick: handleClose })}
              </Box>
            </Box>
          </Modal>
        </SbEditable>
      </ThemeProvider>
    </Layout>
  )
}

export default FormModal
